import { SET_COUNTRY, SET_LANGUAGE } from '../constants/locale'
import { LOAD_CONTENT } from '../constants/content'

const initialState = {
  countries: [],
  languages: [
    { id: 1, name: 'Česky', slug: 'cs', name_: {}},
    { id: 2, name: 'English', slug: 'en', name_: {}},
    { id: 3, name: 'Deutsch', slug: 'de', name_: {}},
    { id: 4, name: 'Polski', slug: 'pl', name_: {}},
    { id: 5, name: 'Rусский', slug: 'ru', name_: {}},
  ],
  selectedLanguage: {
    id: 1,
    slug: 'cs'
  },
  selectedCountry: 2,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_CONTENT: {
      return {
        ...state,
        countries: payload.countries,
        languages: payload.languages
      }
    }
    case SET_COUNTRY: {
      return {
        ...state,
        selectedCountry: Number(payload),
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: {
          id: payload.id,
          slug: payload.slug,
        },
      }
    }
    default: {
      return state
    }
  }
}