// Actions
export const LOAD_CONTENT = Symbol.for('content.loadContent')
export const LOADING_START = Symbol.for('content.loadingStart')
export const LOADING_STOP = Symbol.for('content.loadingStop')
export const INIT_PAGINATION = Symbol.for('content.initPagination')
export const SHOW_PAGE = Symbol.for('content.paginate')

// Events
export const CONTENT_CHANGED = Symbol.for('content.contentChanged')
export const CONTENT_LEAVE = Symbol.for('content.contentLeave')
