import {
  AUTH_START,
  AUTH_STOP,
  LOGIN_GUEST,
  LOGIN_USER,
  LOGOUT_USER,
  SET_VEHICLE,
  USER_ERROR,
  USER_GUEST
} from '../constants/user'

const initialState = {
  id: null,
  name: '',
  email: '',
  phone: '',
  number: '',
  subjects: [],
  licences: [],
  documents: [],
  authenticationInProgress: true,
  isGuest: true,
  selectedVehicleType: 2,
  vehicleTypes: [
    { id: 1, name: 'Do 3.5t', },
    { id: 2, name: 'Nad 3.5t', },
    { id: 3, name: 'Bus', },
  ],
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START: {
      return {
        ...state,
        authenticationInProgress: true,
      }
    }
    case AUTH_STOP:
    case LOGOUT_USER: {
      return {
        ...initialState,
        authenticationInProgress: false,
      }
    }
    case LOGIN_GUEST: {
      return {
        ...initialState,
        authenticationInProgress: false,
        id: USER_GUEST,
        isGuest: true
      }
    }
    case LOGIN_USER: {
      return {
        ...state,
        ...action.payload,
        authenticationInProgress: false,
        isGuest: false
      }
    }
    case SET_VEHICLE: {
      return {
        ...state,
        selectedVehicleType: action.payload
      }
    }
    case USER_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}