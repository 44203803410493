export default class ApiError extends Error {
  constructor(res) {
    super()
    this.name = res.statusText
    this.statusCode = res.status
    this.url = res.url
    this.rawResponse = res
  }
}

export const logError = (e) => {
  // TODO: log to some service
  console.log('error occurred', e)
}
