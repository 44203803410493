import config from '../config'
import ApiError from '../utils/errors'

class API {
  constructor(apiUrl) {
    this.apiUrl = apiUrl
  }

  async loadContent() {
    const res = await fetch(`${this.apiUrl}/articles`)
    return res.json()
  }

  async login(number) {
    const formData = new FormData();
    formData.append('number', number);
    const res = await fetch(`${this.apiUrl}/login`, {
      method: 'POST',
      body: formData
    })

    if (res.ok) {
      return res.json()
    }

    throw new ApiError(res)
  }
}

export default new API(config.apiUrl[config.env])
