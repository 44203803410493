import { INIT_PAGINATION, LOAD_CONTENT, LOADING_START, LOADING_STOP, SHOW_PAGE } from '../constants/content'

const initialState = {
  sections: [],
  articles: [],
  loading: false,
  pagination: {
    page: 1,
    total: 1
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_CONTENT: {
      return {
        ...state,
        sections: payload.sections,
        articles: payload.articles,
        loading: false,
      }
    }
    case LOADING_START: {
      return {
        ...state,
        loading: true
      }
    }
    case LOADING_STOP: {
      return {
        ...state,
        loading: false
      }
    }
    case INIT_PAGINATION: {
      return {
        ...state,
        pagination: payload,
      }
    }
    case SHOW_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload
        },
      }
    }
    default: {
      return state
    }
  }
}