import { combineReducers } from 'redux'
import locale from './locale'
import user from './user'
import content from './content'
import app from './app'

const rootReducer = combineReducers({
  locale,
  user,
  content,
  app
})

export default rootReducer
