import {store} from '../index'
import cs from '../locales/cs'
import en from '../locales/en'
import de from '../locales/de'
import pl from '../locales/pl'
import ru from '../locales/ru'

const __ = (str) => {
  const lang = store.getState().locale.selectedLanguage.slug
  switch (lang) {
    case 'cs': return cs[str] || str
    case 'en': return en[str] || str
    case 'de': return de[str] || str
    case 'pl': return pl[str] || str
    case 'ru': return ru[str] || str
    default: return str
  }
}

export {
  __
}