import React, { Component } from 'react'
import styled from 'styled-components'
import loader from '../../assets/img/loader.svg'

class Loader extends Component {
  render() {
    return (
      <Wrapper>
        <Icon src={loader}/>
      </Wrapper>
    )
  }
}

const Icon = styled.img`
  width: 160px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`

export default Loader
