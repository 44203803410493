export default {
  sosColor: '#e84118',
  gray: '#E4E5E6',
  lightGray: '#f3f3f3',
  darkGray: '#c8c8c8',
  primary: '#1CACD8',
  black: '#222',
  yellow: '#fbc531',
  primaryGradient: 'linear-gradient(to right, #1A9AC2, #2AB7E3)'
}
