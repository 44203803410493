import { APP_ERROR, CLEAR_SEARCH, SEARCH_QUERY, DONT_CLEAR } from '../constants/app'

const initialState = {
  error: null,
  searchQuery: '',
  shouldClear: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      }
    }
    case CLEAR_SEARCH: {
      return {
        ...state,
        searchQuery: '',
        shouldClear: true
      }
    }
    case DONT_CLEAR: {
      return {
        ...state,
        shouldClear: false
      }
    }
    default: {
      return state
    }
  }
}