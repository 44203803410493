import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'balloon-css/balloon.min.css'
import './polyfill'
import * as serviceWorker from './serviceWorker'
import Loader from './components/Common/Loader'
import { configureStore } from './store'
import api from './services/api'
import theme from './theme'
import './scss/index.scss'
import ErrorBoundary from './containers/ErrorBoundary'

const App = Loadable({
  loader: () => import('./containers/index'),
  loading: Loader
})

// create Redux store
export const store = configureStore(api)

ReactDOM.render((
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <BrowserRouter>
          <Switch>
            <Route path="/login" name="Login" component={App}/>
            <Route path="/" name="App" component={App}/>
          </Switch>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </Provider>
), document.getElementById('root'))

serviceWorker.unregister()
