// Actions
export const LOGIN_USER = Symbol.for('user.loginUser')
export const LOGIN_GUEST = Symbol.for('user.loginGuest')
export const LOGOUT_USER = Symbol.for('user.logoutUser')
export const AUTH_START = Symbol.for('user.authStart')
export const AUTH_STOP = Symbol.for('user.authStop')
export const SET_VEHICLE = Symbol.for('user.setVehicle')
export const USER_ERROR = Symbol.for('user.loginError')

export const USER_KEY = 'user'
export const USER_GUEST = 'guest'
