import React, { Component } from 'react'
import styled from 'styled-components'
import { __ } from '../../services/translate'

export default class ErrorNotice extends Component {
  state = {
    open: true
  }

  close = () => this.setState({ open: false })

  render() {
    if (!this.state.open) {
      return null
    }

    return (
      <Wrapper>
        <Notice>
          <span>
            <span>{this.props.children}. </span>
            <span>{__('Skuste stránku')} </span>
            <a href={window.location.href}>{__('znova načitat')}</a>
            <span> {__('pokud problém přetrvává nevahejte nas kontaktovat.')}</span>
          </span>
          <span onClick={this.close}><i className="fa fa-close fa-2x"/></span>
        </Notice>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  background: rgba(255, 255, 255,.5);
  position: fixed;
  top: 0; 
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`

const Notice = styled.div`
  background: ${({ theme }) => theme.sosColor};
  padding: 1rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  a {
    color: #fff;
    text-decoration: underline;
    
    &:hover {
     color: #fff;
    }
  }

  @media all and (max-width: 360px) {
    padding: .2rem 1rem;
  }
`
