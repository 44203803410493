import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { logError } from '../utils/errors'
import { __ } from '../services/translate'
import ErrorNotice from '../components/Common/ErrorNotice'

class ErrorBoundary extends Component {
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    return {
      error: __('Nastala chyba v aplikaci')
    }
  }

  componentDidCatch(error, info) {
    logError({ error, info })
  }

  render() {
    const error = this.props.error || this.state.error || null
    return (
      <Fragment>
        {error && <ErrorNotice>{error}</ErrorNotice>}
        {this.props.children}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error
})

export default connect(mapStateToProps)(ErrorBoundary)
