import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

export const configureStore = (api) => {
  return createStore(
    rootReducer,
    applyMiddleware(thunk.withExtraArgument(api))
  )
}
