const config = {
  env: process.env.NODE_ENV,
  apiUrl: {
    development: 'http://127.0.0.1:8080/https://blutacho.lacosta.e-solutions.cz/api',
    production: 'https://blutacho.lacosta.e-solutions.cz/api'
  },
  defaultLang: 'cs',
  newsSectionId: 9,
  volvoSectionId: 12,
  daysBeforeHolidayNotification: 7,
  pagination: {
    perPage: 10,
    key: 'page'
  }
}

export default config
